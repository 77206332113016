<template>
  <div class="m-page">
    <div class="img-container">
      <!-- <img :src="introductionImgSrc" @click="handlePreview" /> -->
      <!-- [WIP] -->
      <img src="https://img.miaocang.cc/bzythczrr2fx5x6zfufe.jpg" />
    </div>
  </div>
</template>

<script>
import { ImagePreview } from 'vant'

export default {
  name: 'CloudSchedule2023',

  data() {
    return {
      introductionImgSrc: 'https://img.miaocang.cc/pcImg/cloud/2023-schedule.jpg'
    }
  },

  created() {
    document.title = '展会日程'
  },

  methods: {
    /* 大图预览 */
    handlePreview() {
      ImagePreview({
        images: [this.introductionImgSrc],
        closeable: true,
        showIndex: false,
        maxZoom: 10,
        getContainer() {
          return document.querySelector('.m-page')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.m-page {
  min-height: 100vh;
  max-width: 840px;
  text-align: center;
  background-color: #000;
  display: flex;
  align-items: center;
  // background: radial-gradient(farthest-side at top left, #cd96e8, transparent), radial-gradient(farthest-side at top right,#0961bd, transparent), radial-gradient(farthest-side at bottom left,#4a44cc, transparent), radial-gradient(farthest-side at bottom right,#21bcb8, transparent);
  background: radial-gradient(farthest-side at top left, #cd96e8, transparent), radial-gradient(farthest-side at top right,#0961bd, transparent), radial-gradient(farthest-side at bottom left,#18b4e7, transparent), radial-gradient(farthest-side at bottom right,#1adadb, transparent);
  img {
    width: 100vw;
    min-width: 320px;
    max-width: 840px;
  }
}
</style>